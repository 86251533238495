

import { setLocale, i18n } from './i18n'

export default {
  name: 'App',
  data() {
    return {
      user: {},
      shipTos: [],
      portalUrl: '',
      currentYear: new Date().getFullYear(),
      region: this.$route.query.region
        ? this.$route.query.region.toUpperCase()
        : 'GB',
      regionLanguage: '',
      standardCompany: {
        name: 'AirProducts',
        url: 'https://www.airproducts.com',
        logoUrl: 'https://inc.airproducts.com/template/img/ap_logo.png',
        logoWidth: 206,
        logoHeight: 42,
      },
      company: { ...this.standardCompany },
      footLinks: {
        facebookLink: 'https://www.facebook.com/airproductscareers',
        twitterLink: 'https://www.twitter.com/AirProducts',
        youTubeLink: 'https://www.youtube.com/airproducts',
        linkedInLink: 'https://www.linkedin.com/company/air-products',
        legalNoticeLink: 'https://www.airproducts.com/legal-notice',
        privacyNoticeLink: 'https://www.airproducts.com/privacy-notice',
        cookieNoticeLink: 'https://www.airproducts.com/cookie-notice'
      },
    }
  },
  mounted() {
    let self = this
    //enable google tag manager
    var containerId = process.env.VUE_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID
    this.enableGTM(window, document, 'script', 'dataLayer', containerId)
    var locale = self.$route.query.locale ? self.$route.query.locale : 'en-US'
    setLocale(locale)
    // Initialize the Air Products HTML template javascript after the DOM has been established
    window.apci.init()
    this.setupHeaderFooterLinks()
  },
  methods: {
    setupHeaderFooterLinks() {
      this.regionLanguage = this.region + ' - ' + i18n.languageName
      switch (this.region) {
        case 'ES':
          this.company.name = 'Carburos'
          this.company.url = 'https://www.carburos.com'
          this.company.logoUrl =
            'https://inc.airproducts.com/template/img/es_logo.png'
          this.company.logoWidth = 193
          this.company.logoHeight = 58
          this.footLinks.linkedInLink =
            'https://www.linkedin.com/company/137378'
          this.footLinks.twitterLink = 'https://twitter.com/Carburos'
          this.footLinks.legalNoticeLink =
            'http://www.carburos.com/legal-notice.aspx'
          break
        case 'PT':
          this.company.name = 'Gasin'
          this.company.url = 'https://www.gasin.com'
          this.company.logoUrl =
            'https://inc.airproducts.com/template/img/pt_logo.png'
          this.company.logoWidth = 193
          this.company.logoHeight = 52
          this.footLinks.twitterLink = 'https://twitter.com/Gasin_PT'
          this.footLinks.legalNoticeLink =
            'http://www.gasin.com/legal-notice.aspx'
          break
        case 'CL':
          this.company = this.standardCompany;
          break
      }
    },
    enableGTM(w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    }
  }
}
