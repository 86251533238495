import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  role: "main"
}
const _hoisted_2 = { id: "ap-wrapper" }
const _hoisted_3 = { id: "ap-canvas" }
const _hoisted_4 = { class: "ap-top-bar d-none d-md-block" }
const _hoisted_5 = { class: "region-language" }
const _hoisted_6 = {
  id: "ap-top-menu",
  class: "d-none d-md-block"
}
const _hoisted_7 = { id: "ap-logo" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["src", "alt", "width", "height"]
const _hoisted_10 = { id: "my-ap-link" }
const _hoisted_11 = { href: "https://my.airproducts.com" }
const _hoisted_12 = { class: "ap-my-company" }
const _hoisted_13 = { id: "ap-flex" }
const _hoisted_14 = { id: "ap-site-content" }
const _hoisted_15 = {
  id: "global-footer",
  class: "d-none d-md-block"
}
const _hoisted_16 = { id: "footer-links" }
const _hoisted_17 = ["href"]
const _hoisted_18 = ["href"]
const _hoisted_19 = ["href"]
const _hoisted_20 = ["href"]
const _hoisted_21 = { id: "copyright" }
const _hoisted_22 = ["href"]
const _hoisted_23 = ["href"]
const _hoisted_24 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_b_col = _resolveComponent("b-col")
  const _component_b_row = _resolveComponent("b-row")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString($data.regionLanguage), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("a", {
              href: $data.company.url
            }, [
              _createElementVNode("img", {
                src: `${$data.company.logoUrl}`,
                alt: $data.company.name,
                width: $data.company.logoWidth,
                height: $data.company.logoHeight
              }, null, 8, _hoisted_9)
            ], 8, _hoisted_8)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("a", _hoisted_11, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "ap-blue" }, "My", -1)),
              _createElementVNode("span", _hoisted_12, _toDisplayString($data.company.name), 1),
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "ap-service-mark" }, "℠", -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_router_view)
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { class: "ps-4" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("img", {
                    src: "https://inc.airproducts.com/template/img/ap_logo.png",
                    alt: "AirProducts Home",
                    width: "150",
                    height: "30"
                  }, null, -1)
                ])),
                _: 1
              }),
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("a", {
                      class: "link link--social",
                      target: "_blank",
                      rel: "noopener",
                      href: $data.footLinks.facebookLink,
                      title: "Like us on Facebok text",
                      "data-tracking": "[{'event':'click', 'label': 'socialFtClick', 'data': {'socialNetworkName': 'facebook'}}]"
                    }, _cache[3] || (_cache[3] = [
                      _createElementVNode("svg", {
                        class: "footer-icon",
                        "aria-labelledby": "title--facebook",
                        role: "img"
                      }, [
                        _createElementVNode("title", {
                          class: "title--facebook",
                          lang: "en"
                        }, "facebook"),
                        _createElementVNode("use", { "xlink:href": "#icon-facebook" }),
                        _createElementVNode("svg", {
                          viewBox: "0 0 1024 1024",
                          id: "icon-facebook"
                        }, [
                          _createElementVNode("path", { d: "M785.243.183L643.596 0C484.22 0 381.321 98.939 381.321 251.979v116.242H238.76c-12.307 0-22.298 9.321-22.298 20.897v168.331c0 11.576 9.992 20.897 22.298 20.897h142.561v424.757c0 11.576 9.992 20.897 22.298 20.897h185.938c12.368 0 22.298-9.382 22.298-20.897V578.346h166.687c12.307 0 22.298-9.382 22.298-20.897V389.118a20.23 20.23 0 0 0-6.446-14.793l-.012-.011a22.99 22.99 0 0 0-15.629-6.093l-.223.001H611.915v-98.513c0-47.338 12.063-71.402 77.921-71.402h95.405c12.307 0 22.298-9.382 22.298-20.897V21.08c0-11.514-9.992-20.835-22.237-20.897z" })
                        ])
                      ], -1)
                    ]), 8, _hoisted_17),
                    _createElementVNode("a", {
                      class: "link link--social",
                      target: "_blank",
                      rel: "noopener",
                      href: $data.footLinks.twitterLink,
                      title: "Follow us on Twitter",
                      "data-tracking": "[{'event':'click', 'label': 'socialFtClick', 'data': {'socialNetworkName': 'twitter'}}]"
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("svg", {
                        class: "footer-icon",
                        "aria-labelledby": "title--twitter",
                        role: "img"
                      }, [
                        _createElementVNode("title", {
                          class: "title--twitter",
                          lang: "en"
                        }, "twitter"),
                        _createElementVNode("use", { "xlink:href": "#icon-twitter" }),
                        _createElementVNode("svg", {
                          viewBox: "0 0 18 15",
                          id: "icon-twitter"
                        }, [
                          _createElementVNode("path", {
                            "fill-rule": "evenodd",
                            d: "M15.874 2.311A3.707 3.707 0 0 0 17.497.27a7.44 7.44 0 0 1-2.346.896 3.692 3.692 0 0 0-6.29 3.366A10.479 10.479 0 0 1 1.254.676a3.665 3.665 0 0 0-.5 1.856 3.69 3.69 0 0 0 1.642 3.072A3.69 3.69 0 0 1 .724 5.14v.046a3.696 3.696 0 0 0 2.96 3.62c-.309.084-.635.13-.972.13-.238 0-.469-.024-.695-.069a3.693 3.693 0 0 0 3.448 2.564A7.405 7.405 0 0 1 .88 13.01c-.298 0-.591-.018-.88-.05a10.432 10.432 0 0 0 5.658 1.661c6.79 0 10.502-5.626 10.502-10.505l-.012-.478a7.372 7.372 0 0 0 1.846-1.907 7.37 7.37 0 0 1-2.12.581z"
                          })
                        ])
                      ], -1)
                    ]), 8, _hoisted_18),
                    _createElementVNode("a", {
                      class: "link link--social",
                      target: "_blank",
                      rel: "noopener",
                      href: $data.footLinks.linkedInLink,
                      title: "Connect with us on LinkedIn",
                      "data-tracking": "[{'event':'click', 'label': 'socialFtClick', 'data': {'socialNetworkName': 'linkedin'}}]"
                    }, _cache[5] || (_cache[5] = [
                      _createElementVNode("svg", {
                        class: "footer-icon",
                        "aria-labelledby": "title--linkedin",
                        role: "img"
                      }, [
                        _createElementVNode("title", {
                          class: "title--linkedin",
                          lang: "en"
                        }, "linkedin"),
                        _createElementVNode("use", { "xlink:href": "#icon-linkedin" }),
                        _createElementVNode("svg", {
                          viewBox: "0 0 17 17",
                          id: "icon-linkedin"
                        }, [
                          _createElementVNode("path", {
                            "fill-rule": "evenodd",
                            d: "M16.932 10.399v6.545h-3.63v-6.11c0-1.536-.524-2.58-1.838-2.58-1.004 0-1.597.707-1.862 1.387-.095.245-.124.588-.124.927v6.375H5.851S5.9 6.6 5.851 5.528H9.48v1.618c-.005.013-.016.024-.02.039h.02v-.04c.485-.777 1.343-1.885 3.271-1.885 2.393 0 4.182 1.631 4.182 5.139zM2.054.023C.813.023 0 .875 0 1.998 0 3.09.789 3.97 2.007 3.97h.022c1.268 0 2.054-.88 2.054-1.973C4.06.875 3.297.023 2.053.023zM.217 16.944h3.628V5.527H.217z"
                          })
                        ])
                      ], -1)
                    ]), 8, _hoisted_19),
                    _createElementVNode("a", {
                      class: "link link--social",
                      target: "_blank",
                      rel: "noopener",
                      href: $data.footLinks.youTubeLink,
                      title: "Subscribe to our YouTube Channel",
                      "data-tracking": "[{'event':'click', 'label': 'socialFtClick', 'data': {'socialNetworkName': 'youtube'}}]"
                    }, _cache[6] || (_cache[6] = [
                      _createElementVNode("svg", {
                        class: "footer-icon",
                        "aria-labelledby": "title--youtube",
                        role: "img"
                      }, [
                        _createElementVNode("title", {
                          class: "title--youtube",
                          lang: "en"
                        }, "youtube"),
                        _createElementVNode("use", { "xlink:href": "#icon-youtube" }),
                        _createElementVNode("svg", {
                          viewBox: "0 0 18 13",
                          id: "icon-youtube"
                        }, [
                          _createElementVNode("path", {
                            "fill-rule": "evenodd",
                            d: "M17.596 2.21c.179.997.404 2.47.404 4.29-.045 1.82-.225 3.293-.404 4.29-.18.91-.943 1.603-1.886 1.733-1.48.217-3.949.477-6.732.477-2.738 0-5.208-.26-6.734-.477C1.302 12.393.54 11.7.36 10.79A24.45 24.45 0 0 1 0 6.5c0-1.82.18-3.293.36-4.29C.538 1.3 1.301.607 2.243.477A48.279 48.279 0 0 1 8.978 0c2.783 0 5.207.26 6.732.477.943.13 1.707.823 1.886 1.733zM7.182 9.533L12.57 6.5 7.182 3.467v6.066z"
                          })
                        ])
                      ], -1)
                    ]), 8, _hoisted_20)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { class: "ps-4 mt-4" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("span", null, "Copyright © 1996-" + _toDisplayString($data.currentYear) + " Air Products Inc. All Rights Reserved.", 1),
                    _cache[7] || (_cache[7] = _createTextVNode(" | ")),
                    _createElementVNode("a", {
                      class: "link link--default",
                      href: $data.footLinks.legalNoticeLink
                    }, "Legal Notice", 8, _hoisted_22),
                    _cache[8] || (_cache[8] = _createTextVNode(" | ")),
                    _createElementVNode("a", {
                      class: "link link--default",
                      href: $data.footLinks.privacyNoticeLink
                    }, "Privacy Notice", 8, _hoisted_23),
                    _cache[9] || (_cache[9] = _createTextVNode(" | ")),
                    _createElementVNode("a", {
                      class: "link link--default",
                      href: $data.footLinks.cookieNoticeLink
                    }, "Cookie Notice", 8, _hoisted_24)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("div", { id: "ap-loading-overlay" }, [
      _createElementVNode("div", { id: "ap-loading-image" })
    ], -1))
  ]))
}